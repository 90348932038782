import React, {useEffect, useMemo, useState} from 'react';
import {
    Card,
    CardContent,
    CircularProgress,
    CardActions,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow, TableCell, TableBody, Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import {FitnessCenter, EventBusy, FlashOff, SyncDisabled, Leaderboard as LeaderboardIcon} from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { format, parse } from "date-fns";
import {Link, useNavigate, useParams} from "react-router-dom";
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import MmfAuthButton from "../_common/buttons/mmf";
import FitbitAuthButton from "../_common/buttons/fitbit";
import StravaAuthButton from "../_common/buttons/strava";
import GarminAuthButton from "../_common/buttons/garmin";
import {
    activitySelector,
    activityLoadingSelector,
    authInitializedSelector,
    loggingActivitySelector,
    userIdSelector,
    profileUserIdSelector
} from "../../redux/login/selectors";
import {deleteActivity, fetchRecentActivity} from "../../redux/login";
import LoadingView from "../_common/loading";
import {Delete} from "@mui/icons-material";
import {
    pastRegistrationsLoadingSelector,
    pastRegistrationsByYearSelector,
    ytdChartStatsSelector,
    ytdStatsByYearSelector
} from "../../redux/events/selectors";
import BigTicker from "../dashboard/event/bigTicker";
import EventGoal from "../dashboard/event/goal";
import EventStandings from "../dashboard/event/standings";
import HCDialog from "../_common/dialog";
import Leaderboard from "../leaderboards/leaderboard";
import { ACTIVITY_TYPES } from "../../utils";
import LogActivityDialog from "./logActivityDialog";
import YearSelect from "../_common/select/year";
import {fetchPastRegistrations, fetchYtdStats} from "../../redux/events";
import TrackableButton from "../_common/buttons/trackable";
import TrackableIconButton from "../_common/buttons/trackable/iconButton";
import EventHeader from "../_common/events/header";
import {bgClass} from "../dashboard/event/register";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MyStandings from "../dashboard/event/myStandings";

import mmrLogoDark from "../../img/trackers/map_my_run_logo_dark.svg";
import fitbitLogoDark from "../../img/trackers/fitbit-new-logo-header-dark.svg";
import garminLogoDark from "../../img/trackers/garmin_logo_dark.svg";
import stravaLogoDark from "../../img/trackers/Strava_Logo_logo_dark.svg";

// const APIS: {[id: number]: string} = {
//     1: "MapMyRun",
//     2: "Fitbit",
//     3: "Strava",
//     4: "Garmin"
// }

const DISPLAY_PAGE_SIZE = 4;
const SERVER_PAGE_SIZE = 50;

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const COLOR_FOR_ACTIVITY = {
    5: '#4dc9f6',
    6: '#f67019',
    7: '#f53794',
    8: '#537bc4',
    9: '#acc236',
    10: '#166a8f',
    11: '#00a950',
    12: '#58595b',
    13: '#8549ba',
}

const LABELS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const MyActivity: React.FC = () => {
    const [page, setPage] = useState(1);
    const [year, setYear] = useState(new Date().getFullYear());
    const [showLogActivity, setShowLogActivity] = useState(false);
    const activities = useSelector(activitySelector);
    const activityLoading = useSelector(activityLoadingSelector);
    const authInitialized = useSelector(authInitializedSelector);
    const pastRegistrationsLoading = useSelector(pastRegistrationsLoadingSelector);
    const pastRegistrations = useSelector(pastRegistrationsByYearSelector(year));
    const loggingActivity = useSelector(loggingActivitySelector);
    const chartStats = useSelector(ytdChartStatsSelector(year));
    const lifetimeStats = useSelector(ytdStatsByYearSelector("lifetime"));
    const userId = useSelector(userIdSelector);
    const profileUserId = useSelector(profileUserIdSelector);
    const { eventId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    // TODO: Only fetch registrations for current year once we update the domain model
    useEffect(() => {
        if (!pastRegistrations.length) {
            // @ts-ignore
            dispatch(fetchPastRegistrations(userId, enqueueSnackbar))
        }
    // }, [dispatch, enqueueSnackbar, pastRegistrations.length, year]);
    }, [dispatch, enqueueSnackbar, pastRegistrations.length, userId]);

    useEffect(() => {
        if (authInitialized) {
            // @ts-ignore
            dispatch(fetchRecentActivity(0, SERVER_PAGE_SIZE, enqueueSnackbar));
        }
    }, [authInitialized, dispatch, enqueueSnackbar]);

    const visibleActivities = useMemo(() => {
        return activities?.slice(0, DISPLAY_PAGE_SIZE * page);
    }, [activities, page]);

    useEffect(() => {
        const numPages = Math.ceil(activities?.length / DISPLAY_PAGE_SIZE);
        const pagesLeft = numPages - page;
        if (pagesLeft === 1 && activities.length % SERVER_PAGE_SIZE === 0) {
            console.log("=== FETCHING ANOTHER PAGE OF DATA ===");
            // @ts-ignore
            dispatch(fetchRecentActivity(activities.length, SERVER_PAGE_SIZE, enqueueSnackbar));
        }
    }, [page, activities.length, dispatch, enqueueSnackbar]);

    useEffect(() => {
        if (profileUserId) {
            // @ts-ignore
            dispatch(fetchYtdStats(profileUserId, "lifetime", enqueueSnackbar));
        }
    }, [profileUserId, dispatch, enqueueSnackbar]);

    useEffect(() => {
        if (profileUserId) {
            // @ts-ignore
            dispatch(fetchYtdStats(profileUserId, year, enqueueSnackbar));
        }
    }, [year, profileUserId, dispatch, enqueueSnackbar]);

    const {totalsByMonth, totalsByActivity, annualTotals} = useMemo(() => {
        const annualTotals = {
            totalMiles: 0,
            totalMinutes: 0,
            totalCalories: 0,
        }

        const totalsByMonth = Array.from(Array(12)).reduce((ret, _, index) => ({
            ...ret,
            [index]: {
                totalMiles: 0,
                totalMinutes: 0,
                totalCalories: 0,
            }
        }), {});

        const totalsByActivity = chartStats?.map(stat => {
            return stat.data.reduce((ret, item, index) => {
                const miles = parseFloat(item.totalMiles);
                const minutes = parseFloat(item.totalMinutes);
                const calories = parseFloat(item.totalCalories);

                totalsByMonth[index].totalMiles += miles
                totalsByMonth[index].totalMinutes += minutes;
                totalsByMonth[index].totalCalories += calories;

                annualTotals.totalMiles += miles
                annualTotals.totalMinutes += minutes;
                annualTotals.totalCalories += calories;

                return {
                    totalMiles: ret.totalMiles + miles,
                    totalMinutes: ret.totalMinutes + minutes,
                    totalCalories: ret.totalCalories + calories,
                }
            }, { totalMiles: 0, totalMinutes: 0, totalCalories: 0 });
        });

        return {totalsByMonth: Object.values(totalsByMonth), totalsByActivity, annualTotals};
    }, [chartStats]);

    const openFAQs = () => window.open("https://healthcode.org/faq-activity-data/");

    const openSmartGoals = () => window.open("https://healthcode.org/smartgoalsguide/");

    return (
        <div className="flex flex-col items-center lg:px-4">
            <div className="w-full">
                <div className="uppercase font-bold text-sm flex-1 flex items-end">
                    Connect a device/app (optional)
                </div>
                {process.env.REACT_APP_DISABLE_TRACKERS === "true" ?
                    <div className="text-center mt-3">
                        <div>
                            <SyncDisabled fontSize="large" />
                        </div>
                        <div>
                            Third party trackers have been disabled in Prod until the official cut-over on 11/30/2022.
                        </div>
                    </div> :
                    <>
                        <div className="flex flex-col md:flex-row">
                            <div className="w-full md:w-[350px] md:mr-4">
                                <MmfAuthButton fullWidth />
                            </div>
                            <div className="w-full md:w-[350px]">
                                <FitbitAuthButton fullWidth />
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row mt-1">
                            <div className="md:w-[350px] md:mr-4">
                                <StravaAuthButton fullWidth />
                            </div>
                            <div className="md:w-[350px]">
                                <GarminAuthButton fullWidth />
                            </div>
                        </div>
                        <div className="mt-3">
                            <TrackableButton eventName="my_activity_faqs_click" color="inherit" onClick={openFAQs}>
                                FAQs: Apple, Activity Data & Sync
                            </TrackableButton>
                        </div>
                    </>
                }
                <div className="uppercase font-bold text-sm flex-1 flex items-end mt-12">
                    READY TO MEET YOUR FITNESS GOALS?
                </div>
                <div className="mb-4 mt-2">
                    Set and track your goals…the SMART way.
                </div>
                <div>
                    <TrackableButton variant="contained" eventName="smart_goals_btn_click" color="inherit" onClick={openSmartGoals}>
                        Open SMART Goals Guide
                    </TrackableButton>
                </div>
                {activityLoading ?
                    <LoadingView /> :
                    <div>
                        <div className="flex mb-2 mt-16">
                            <div className="uppercase font-bold text-sm flex-1 flex items-end">
                                Recent Activity
                            </div>
                            <div className="mb-1">
                                {loggingActivity ?
                                    <div className="mr-6">
                                        <CircularProgress size={35}/>
                                    </div> :
                                    <TrackableButton eventName="my_activity_log_activity_click" variant="contained" onClick={() => setShowLogActivity(true)}>
                                        <FitnessCenter className="mr-1"/>Log an Activity
                                    </TrackableButton>
                                }
                            </div>
                        </div>
                        <div>
                            {visibleActivities?.length ?
                                <div>
                                    {visibleActivities.map(activity => (
                                        <ActivityItem activity={activity} key={activity.id} />
                                    ))}
                                    {visibleActivities.length < activities.length &&
                                        <div className="flex justify-center">
                                            <TrackableButton eventName="my_activity_show_more_activities_click" color="primary" onClick={() => setPage(page + 1)}>
                                                + Show More Activities
                                            </TrackableButton>
                                        </div>
                                    }
                                </div> :
                                // <TableContainer component={Paper}>
                                //     <Table aria-label="activity table">
                                //         <TableHead>
                                //             <TableRow>
                                //                 <TableCell align="left"><strong>Date</strong></TableCell>
                                //                 <TableCell align="left"><strong>Activity</strong></TableCell>
                                //                 <TableCell align="left"><strong>Source</strong></TableCell>
                                //                 <TableCell align="left"><strong>Miles</strong></TableCell>
                                //                 <TableCell></TableCell>
                                //             </TableRow>
                                //         </TableHead>
                                //         <TableBody>
                                //             {activities.map((activity) => (
                                //                 <TableRow key={activity.id}>
                                //                     <TableCell align="left">{format(parse(activity.date, "yyyy-MM-dd", new Date()), "eee, LLL dd, yyyy")}</TableCell>
                                //                     <TableCell align="left">{ACTIVITY_TYPES[activity.activity_type_id]}</TableCell>
                                //                     <TableCell align="left">{activity.api_id ? APIS[activity.api_id] : "HealthCode"}</TableCell>
                                //                     <TableCell align="left">{activity.miles.toFixed(2)}</TableCell>
                                //                     <TableCell align="left">
                                //                         <IconButton>
                                //                             <Delete />
                                //                         </IconButton>
                                //                     </TableCell>
                                //                 </TableRow>
                                //             ))}
                                //         </TableBody>
                                //     </Table>
                                // </TableContainer> :
                                <div className="text-center text-lg mt-6">
                                    <div>
                                        <FlashOff fontSize="large" />
                                    </div>
                                    <div>
                                        Your recent activity will show up here once you've logged some.
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
                {lifetimeStats &&
                    <div className="mt-16">
                        <div className="uppercase font-bold text-sm mb-2 mt-6">
                            Lifetime Stats
                        </div>
                        <div className="w-full sm:w-[300px]">
                            <BigTicker bigTicker={{
                                totalCalories: lifetimeStats.total_calories,
                                totalMinutes: lifetimeStats.total_minutes,
                                totalMiles: lifetimeStats.total_miles
                            }} />
                        </div>
                    </div>
                }
                <div className="mt-16 flex">
                    {/* @ts-ignore */}
                    <YearSelect value={year} onChange={e => setYear(e.target.value)} variant="standard" />
                </div>
                <div className="mb-20">
                    <div className="uppercase font-bold text-sm mb-2 mt-6">
                        Past Event Stats
                    </div>
                    {pastRegistrationsLoading ?
                        <LoadingView /> :
                        (pastRegistrations.length ?
                            pastRegistrations.map(registration => (
                                <div className="mb-6" key={`registration-${registration.id}`}>
                                    <PastRegistration registration={registration} />
                                </div>
                            )) :
                            <div className="mt-8 text-center text-lg">
                                <div>
                                    <EventBusy fontSize="large" />
                                </div>
                                <div>
                                    Your "past" events will display here when they're completed.
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="mt-16 mb-20">
                    <div className="uppercase font-bold text-sm mb-2">
                        YTD Stats
                    </div>
                    <Card>
                        <CardContent>
                            <div className="flex justify-center h-[300px]">
                                <Line
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        spanGaps: false,
                                        // elements: {
                                        //     line: {
                                        //         tension: 0.000001
                                        //     }
                                        // },
                                        scales: {
                                            y: {
                                                stacked: true,
                                            }
                                        },
                                        // plugins: {
                                        //     filler: {
                                        //         propagate: false
                                        //     },
                                        //     samples_filler_analyser: {
                                        //         target: 'chart-analyser'
                                        //     }
                                        // }
                                        plugins: {
                                            legend: {
                                                position: 'top' as const,
                                            },
                                        },
                                    }}
                                    data={{
                                        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                                        datasets: chartStats?.map(stat => ({
                                            // @ts-ignore
                                            label: ACTIVITY_TYPES[stat.activityTypeId],
                                            data: stat.data.map(item => item.totalMiles),
                                            // @ts-ignore
                                            borderColor: _rgbaStringFromHex(COLOR_FOR_ACTIVITY[stat.activityTypeId], 1.0),
                                            // @ts-ignore
                                            backgroundColor: _rgbaStringFromHex(COLOR_FOR_ACTIVITY[stat.activityTypeId], 0.5),
                                        })) || []
                                        // {
                                        //     label: ,
                                        //     // @ts-ignore
                                        //     data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                                        //     borderColor: 'rgb(255, 99, 132)',
                                        //     backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                        // },
                                        // {
                                        //     label: 'Dataset 2',
                                        //     // @ts-ignore
                                        //     data: [4, 12, 13, 55, 15, 16, 17, 18, 33, 20, 21, 22],
                                        //     borderColor: 'rgb(53, 162, 235)',
                                        //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                        // }

                                    }}
                                />
                            </div>
                        </CardContent>
                    </Card>
                    <TableContainer component={Paper} className="mt-6">
                        <Table aria-label="activity table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left"><strong>Activity</strong></TableCell>
                                    {LABELS.map(label => (
                                        <TableCell key={label}><strong>{label}</strong></TableCell>
                                    ))}
                                    <TableCell align="left"><strong>Total (Annual)</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {chartStats?.map((stat, index) => (
                                    <TableRow key={`stat-${index}`}>
                                        {/* @ts-ignore */}
                                        <TableCell>{ACTIVITY_TYPES[stat.activityTypeId]}</TableCell>
                                        {stat.data.map((item, index) => (
                                            <TableCell key={`stat-month-${index}`}>
                                                <div className="">
                                                    {parseFloat(item.totalMiles).toFixed(1)}&nbsp;mi
                                                </div>
                                                <div>
                                                    {parseFloat(item.totalMinutes).toFixed(1)}&nbsp;min
                                                </div>
                                                <div>
                                                    {parseFloat(item.totalCalories).toFixed(1)}&nbsp;cal
                                                </div>
                                            </TableCell>
                                        ))}
                                        {totalsByActivity &&
                                            <TableCell>
                                                <div className="font-bold">
                                                    {parseFloat(totalsByActivity[index].totalMiles).toFixed(1)}&nbsp;mi
                                                </div>
                                                <div className="font-bold">
                                                    {parseFloat(totalsByActivity[index].totalMinutes).toFixed(1)}&nbsp;min
                                                </div>
                                                <div className="font-bold">
                                                    {parseFloat(totalsByActivity[index].totalCalories).toFixed(1)}&nbsp;cal
                                                </div>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                                <TableRow>
                                    {/* @ts-ignore */}
                                    <TableCell><strong>Monthly Total</strong></TableCell>
                                    {totalsByMonth?.map((item: any, index: number) => (
                                        <TableCell key={`totals-month-${index}`}>
                                            <div className="font-bold">
                                                {parseFloat(item.totalMiles).toFixed(1)}&nbsp;mi
                                            </div>
                                            <div className="font-bold">
                                                {parseFloat(item.totalMinutes).toFixed(1)}&nbsp;min
                                            </div>
                                            <div className="font-bold">
                                                {parseFloat(item.totalCalories).toFixed(1)}&nbsp;cal
                                            </div>
                                        </TableCell>
                                    ))}
                                    <TableCell>
                                        <div className="font-bold">
                                            {annualTotals?.totalMiles.toFixed(1)}&nbsp;mi
                                        </div>
                                        <div className="font-bold">
                                            {annualTotals?.totalMinutes.toFixed(1)}&nbsp;min
                                        </div>
                                        <div className="font-bold">
                                            {annualTotals?.totalCalories.toFixed(1)}&nbsp;cal
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <HCDialog
                open={eventId !== undefined}
                title="Leaderboard"
                actions={[]}
                onClose={() => navigate(-1)}
                fullScreen
            >
                <Leaderboard eventId={parseInt(eventId || "0", 10)} registration={pastRegistrations.find(reg => reg.eventId === eventId)} />
            </HCDialog>
            <LogActivityDialog open={showLogActivity} onClose={() => setShowLogActivity(false)} />
        </div>
    )
};

interface RegistrationProps {
    registration: any;
}

const PastRegistration: React.FC<RegistrationProps> = ({ registration }) => {
    return (
        <>
            <Card>
                <EventHeader bgImgUrl={registration.imageUrls.leaderboard || ""} imgUrl={registration.imageUrls.event || ""} imgAlt={registration.name} />
                <CardContent>
                    {/*<div className="flex w-full">*/}
                    {/*    <div className="flex items-center">*/}
                    {/*        <img src={`${process.env.REACT_APP_ADMIN_URL}/media/${registration.imageUrls.event}`} className="w-32" alt={registration.name} />*/}
                    {/*    </div>*/}
                    {/*    <div className="text-2xl font-bold flex items-center pl-6 w-full">*/}
                    {/*        <div className="flex-1">*/}
                    {/*            {registration.name}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {registration.mileGoal ?
                        <EventGoal totalMiles={registration.totals?.miles} activityTotals={registration.activityTotals} goal={registration.mileGoal} disableEdit /> :
                        <div className="text-center top-divider">
                            No goal set.
                        </div>
                    }
                    <div className="top-divider">
                        <div className="text-sm font-bold uppercase">
                            Registration Level
                        </div>
                        <div className="flex flex-wrap">
                            <div className={`font-hc text-white py-2 px-3 md:px-6 mt-2 ${bgClass(registration.registrationLevelColor)}`}>
                                {registration.registrationLevelName}
                            </div>
                        </div>
                    </div>
                    {/*<div className="top-divider">*/}
                    {/*    {registration.bigTicker ?*/}
                    {/*        <BigTicker bigTicker={registration.bigTicker}/> :*/}
                    {/*        <div className="text-center">*/}
                    {/*            Not enough data for BigTicker.*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*</div>*/}
                    {/*{registration.mileGoal ?*/}
                    {/*    <EventGoal totalMiles={registration.totals?.miles} activityTotals={registration.activityTotals} goal={registration.mileGoal} disableEdit /> :*/}
                    {/*    <div className="text-center top-divider">*/}
                    {/*        No goal set.*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {/*<div>*/}
                    {/*    {(registration.totals?.miles || 0) > 0 ?*/}
                    {/*        <div>*/}
                    {/*            <EventStandings*/}
                    {/*                eventId={registration.eventId}*/}
                    {/*                orgId={registration.orgId}*/}
                    {/*                orgName={registration.orgName}*/}
                    {/*                groupId={registration.groupId}*/}
                    {/*                groupName={registration.groupName} />*/}
                    {/*        </div> :*/}
                    {/*        <div className="top-divider text-center">*/}
                    {/*            No activity recorded.*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*</div>*/}
                    {/*{registration.leaderboard &&*/}
                    {/*    <div className="top-divider">*/}
                    {/*        <NavLink to={`leaderboards/${registration.eventId}`}>*/}
                    {/*            <TrackableButton eventName="my_activity_view_leaderboard_click" fullWidth>*/}
                    {/*                <BarChart className="mr-2" />View Leaderboard*/}
                    {/*            </TrackableButton>*/}
                    {/*        </NavLink>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </CardContent>
                <div>
                    <Accordion disableGutters>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className="text-center text-lg font-bold font-hc tracking-wider py-1 w-full mr-3 text-graydark">
                                Leaderboard Summary
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <div className="grid grid-cols-2 gap-0">
                                    <div className="col-span-full md:col-span-1 border-0 border-solid border-graylight md:border-r md:pr-6">
                                        <div className="font-bold text-sm uppercase mb-2">
                                            International Stats
                                        </div>
                                        <BigTicker bigTicker={registration.bigTicker} />
                                    </div>
                                    <div className="col-span-full md:col-span-1 md:pl-6 pt-6 md:pt-0">
                                        <MyStandings eventId={registration.eventId} />
                                    </div>
                                </div>
                                <div>
                                    <div className="flex justify-center mt-4">
                                        <Link to={`leaderboards/${registration.eventId}`}>
                                            <TrackableButton eventName="my_activity_view_full_leaderboard_click">
                                                <LeaderboardIcon className="mr-1" /> View Full Leaderboard
                                            </TrackableButton>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    {registration?.orgId &&
                        <Accordion disableGutters>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className="text-center text-lg font-bold font-hc tracking-wider py-1 w-full mr-3 text-graydark">
                                    My &quot;{registration?.orgName}&quot; Summary
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <EventStandings eventId={registration.eventId} orgId={registration?.orgId} orgName={registration?.orgName} groupId={registration?.groupId} groupName={registration?.groupName} />
                            </AccordionDetails>
                        </Accordion>
                    }
                </div>
            </Card>
        </>
    )
}

interface ActivityProps {
    activity: any;
}

const ActivityItem: React.FC<ActivityProps> = ({ activity }) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const onDeleteClick = () => {
        setOpen(false);
        window.setTimeout(() => {
            // @ts-ignore
            dispatch(deleteActivity(activity, enqueueSnackbar));
        }, 500);
    }

    const formattedDate = format(parse(activity.date, "yyyy-MM-dd", new Date()), "eee, LLL dd, yyyy");

    return (
        <>
            <Card className="mb-4" key={activity.id}>
                <CardContent>
                    <div className="flex -mb-4">
                        <div className="flex-1">
                            <div className="text-sm font-bold">
                                {formattedDate}
                            </div>
                            <div className="text-lg mt-1">
                                {ACTIVITY_TYPES[activity.activity_type_id]}
                            </div>
                        </div>
                        <div className="font-hc text-xl">
                            <div className="flex justify-end">
                                {activity.loading ?
                                    <CircularProgress size={30} /> :
                                    activity.miles ?
                                        <>
                                            {activity.miles.toFixed(2)} <span className="font-roboto text-base">mi</span>
                                        </> :
                                        <>
                                            {activity.minutes.toFixed(2)} <span className="font-roboto text-base">min</span>
                                        </>
                                }
                            </div>
                            {activity.api_id &&
                                <div className="flex justify-end">
                                    {_logoForApiId(activity.api_id)}
                                </div>
                            }
                        </div>
                    </div>

                </CardContent>
                <CardActions>
                    <div className="flex justify-end w-full">
                        <TrackableIconButton disabled={activity.local} eventName="my_activity_delete_activity_click" onClick={() => setOpen(true)}>
                            <Delete />
                        </TrackableIconButton>
                    </div>
                </CardActions>
            </Card>
            <HCDialog
                open={open}
                title="Delete Activity"
                actions={[
                    { label: 'Cancel', eventName: "my_activity_delete_activity_dialog_cancel_click", onClick: () => setOpen(false) },
                    { label: 'Delete', eventName: "my_activity_delete_activity_dialog_delete_click", onClick: onDeleteClick, color: 'primary' }
                ]}
                onClose={() => setOpen(false)}>
                Are you sure you want to delete {activity.miles.toFixed(2)} miles on {formattedDate}?
            </HCDialog>
        </>
    )
};

function _rgbaStringFromHex(hex: string, alpha: number) {
    const rgb: { r: number, g: number, b: number } | null = _hexToRgb(hex);
    return `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, ${alpha})`
}

function _hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

function _logoForApiId(apiId: string | number) {
    switch (apiId.toString()) {
        case "1":
            return <img src={mmrLogoDark} className="h-3" alt="MapMyFitness" />;
        case "2":
            return <img src={fitbitLogoDark} className="h-4" alt="Fitbit" />;
        case "3":
            return <img src={stravaLogoDark} className="h-3" alt="Strava" />;
        case "4":
            return <img src={garminLogoDark} className="h-6" alt="Garmin" />;
    }
}

export default MyActivity;
